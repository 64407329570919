.wrapper {
  overflow: hidden;
  height: 100%;
  background: var(--white-01);

  .list {
    padding: 0px 12px;
    display: flex;
    flex-direction: column;
    gap: 0;
    height: 100%;
    overflow: auto;
  }
}
