.wrapper {
  border-bottom: 1px solid var(--grey-04);
  padding: 12px 0;
  display: flex;
  align-items: center;
  gap: 12px;
  position: relative;

  .iconBox {
    width: 40px;
    height: 40px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .content {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 6px;
    flex: 1;
  }
}
