.cardWrapper {
  display: flex;
  flex-direction: column;
  gap: 6px;
  border: 1px solid var(--grey-03);
  border-radius: 8px;
  padding: 12px;

  .avatarWrapper {
    display: flex;
    align-items: center;
    gap: 12px;

    .info {
      display: flex;
      flex-direction: column;
      gap: 6px;
      min-width: 200px;
    }
  }

  .status {
    display: flex;
    align-items: center;
    gap: 6px;
  }
}
