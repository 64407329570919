:root {
  /* Primary */
  --blue: #2196f3;
  --orange: #ff9900;

  /* Secondary */
  --dark-blue: #004ad7;
  --ocean-blue: #00d1ff;
  --soft-red: #ff6854;
  --soft-orange: #ffbb64;
  --purple: #8674f5;
  --red: #ff4444;
  --green: #9ade7b;
  --black: #363636;
  --grey-01: #949494;
  --grey-02: #b5b5b5;
  --grey-03: #d7d7d7;
  --grey-04: #f2f2f2;
  --grey-05: #f9f9f9;
  --white-01: #ffffff;

  /* Shade  */
  --blue-02: #1f6cff;
  --light-blue-01: #aecaff;
  --light-blue-02: #e9f0ff;
  --light-green-01: #e8fbef;
  --light-orange-01: #fff7f0;
  --light-red-01: #ffe5e2;
}
