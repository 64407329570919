.wrapper {
  padding: 24px;

  .card {
    padding: 24px;
    border-radius: 16px;
    // height: calc(100vh - 300px);
    background: var(--white-01);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    gap: 24px;

    .price {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .content {
    padding-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 12px;

    .item {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      gap: 12px;

      .left {
        // white-space: nowrap;
        width: calc(60% - 12px);
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .right {
        // white-space: nowrap;
        word-wrap: break-word;
        width: 40%;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: right;
      }
    }
  }
}
