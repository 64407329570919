.icon {
  width: 48px;
  height: 48px;
  background: rgba(255, 255, 255, 0.2);
  border: none;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    width: 24px !important;
    height: 24px !important;
  }
}
