.point {
  --top: 3px;
  --right: 3px;
  background: var(--red);
  :global(.adm-badge-content) {
    font-size: var(--font-xs);
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
