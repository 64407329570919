.panel {
  :global(.adm-floating-panel-header) {
    background-color: var(--white-01);
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    height: 32px;
    box-shadow: var(--shadow-03);
  }

  :global(.adm-floating-panel-bar) {
    background: var(--grey-04) !important;
    height: 4px !important;
    width: 24px !important;
  }

  :global(.adm-floating-panel-content) {
    padding: 0px 24px;
  }
}
