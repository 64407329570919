@import './font-size';
@import './font-weight';
@import './color';
@import './classname';
@import './reset';
@import './overwrite';
@import './box-shadow';

html {
  position: fixed;
  height: 100vh;
  overflow: hidden;
}

body {
  margin: 0 !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  background-color: white;

  // .adm-image-img {
  //   object-fit: contain !important;
  // }
}
