.wrapper {
  width: 100%;
  padding: 0;
  border: none;
  span {
    height: 56px;
    border-radius: 8px;
    border: 1px solid var(--grey-04);
    padding: 0 12px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
  }
}
