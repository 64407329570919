.wrapper {
  overflow: hidden;
  height: 100%;
  background: var(--white-01);
  .controller {
    padding: 0 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    svg {
      fill: var(--blue);
    }
  }
  .list {
    padding: 12px 12px 0 12px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    height: calc(100%);
    overflow: auto;
  }
}
