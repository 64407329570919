.wrapper {
  padding: 24px;
  display: flex;
  flex-direction: column;
  height: calc(100% - 56px);
  width: calc(100% - 48px);
  gap: 24px;
  overflow: auto;
  .content {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  .card {
    padding: 24px;
    border-radius: 16px;
    height: calc(100vh - 300px);
    min-height: 450px;
    background: var(--white-01);
    display: flex;
    flex-direction: column;
    position: relative;
    .cardContent {
      display: flex;
      flex-direction: column;
      gap: 24px;
      flex: 1;
      .divider {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 24px;
        position: relative;
        .left {
          width: 20px;
          height: 20px;
          background: var(--green);
          border-radius: 20px;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: -34px;
        }
        .right {
          width: 20px;
          height: 20px;
          background: var(--green);
          border-radius: 20px;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: -34px;
        }
      }
    }
    .status {
      position: absolute;
      top: 24px;
      right: 24px;
    }
  }
  .groupBtn {
    display: flex;
    align-items: center;
    gap: 12px;
  }
}
