.container {
  .header {
    height: 56px;
    background: var(--blue);
    display: flex;
    align-items: center;
    padding: 0 24px;
    position: fixed;
    top: 0;
    width: calc(100vw - 48px);
  }
  .content {
    margin-top: 56px;
    height: calc(100vh - 56px);
    background: var(--blue);
    background-image: url('../../../public/images/backgrounds/svg/pattern-01.svg');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: bottom;
  }
}
