:root {
  --adm-color-warning: var(--orange) !important;
  --adm-color-danger: var(--red) !important;
  --adm-color-success: var(--green) !important;
  --adm-color-primary: var(--blue) !important;
  --adm-button-border-radius: 8px;

  .zalo-chat-widget {
    bottom: 100px !important;
    right: 12px !important;
  }
}
