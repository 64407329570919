.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  .input {
    width: auto;
    padding: 12px;
    background-color: var(--grey-04);
    border-radius: 8px;
  }
}
