:root {
  --font-us: 10px;
  --font-xs: 12px;
  --font-sm: 14px;
  --font-md: 16px;
  --font-lg: 18px;
  --font-xl: 20px;
  --font-xxl: 24px;
  --font-xxxl: 32px;
}
