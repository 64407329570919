.avatarWrapper {
  padding-top: 24px;
  display: flex;
  justify-content: center;
  width: fit-content;
  margin: auto;
  position: relative;

  .icon {
    position: absolute;
    width: 50px;
    height: 50px;
    bottom: -10px;
    right: -20px;
    border-color: var(--white-01);
    border-width: 4px;
    span {
      display: block;
      width: 18px;
      height: 18px;
      svg {
        font-size: 18px;
      }
    }
  }
  .cropImage {
    position: fixed;
    background: white;
    z-index: 10;
    width: 100%;
    height: 100%;
    top: 0;
    .closeIcon {
      position: fixed;
      top: 68px;
      right: 12px;
      z-index: 11;
    }
    .confirmButton {
      position: fixed;
      bottom: 68px;
      right: 50%;
      transform: translateX(50%);
      z-index: 11;
      width: auto;
      span {
        white-space: nowrap;
      }
    }
  }
}
