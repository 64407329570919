.wrapper {
  .contract {
    margin-top: 70px;
    .listItem {
      margin-top: 12px;
    }
  }
  .transaction {
    margin-top: 24px;
    .listItem {
      margin-top: 12px;
    }
  }
}
