.wrapper {
  padding: 12px;

  .form {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
}
