.capsuleTab {
  :global(.adm-capsule-tabs-tab) {
    padding: 6px 12px;
    color: var(--grey-02);
    border: 1px solid var(--grey-03);
    background: transparent;
  }
  :global(.adm-capsule-tabs-tab-active) {
    background: var(--light-orange-01);
    color: var(--orange);
    border: 1px solid var(--orange);
  }
  :global(.adm-capsule-tabs-header) {
    padding: 0;
    border: none;
  }
  :global(.adm-capsule-tabs-tab-wrapper) {
    &:first-child {
      padding: 0 6px 0 12px;
    }
    &:last-child {
      padding: 0 12px 0 6px;
    }
  }
}
