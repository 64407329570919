.modal {
  :global(.adm-modal-title) {
    font-size: var(--font-md) !important;
  }
  :global(.adm-button) {
    span {
      font-size: var(--font-md) !important;
    }
  }
  :global(.adm-modal-footer) {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    gap: 12px;
  }
  :global(.adm-space-item) {
    width: calc((100% / 2) - 6px);
    margin: 0;

    button {
      height: 100%;
    }
  }
  :global(.adm-modal-button-primary) {
    background: var(--orange) !important;
    color: var(--white-01) !important;
    border: 1px solid var(--orange) !important;
  }
  :global(.adm-modal-button) {
    background: var(--white-01);
    color: var(--orange);
    border: 1px solid var(--orange);
  }
}
