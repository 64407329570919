.wrapper {
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;

  .button {
    position: fixed;
    width: calc(100% - 24px);
    bottom: 24px;
  }
}
