.cardItem {
  height: 56px;
  display: flex;
  gap: 12px;
  align-items: center;
  .iconBox {
    width: 56px;
    height: 56px;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .cardContent {
    display: flex;
    flex-direction: column;
    gap: 6px;
    flex: 1;
  }
}
