.listItem {
  :global(.adm-page-indicator-dot-active) {
    background: var(--black);
  }
  :global(.adm-swiper-slide) {
    .inner {
      margin-right: 12px;
    }

    &:last-child {
      .inner {
        margin-right: 0 !important;
      }
    }
  }
}
