.wrapper {
  overflow: hidden;
  height: 100%;
  background: var(--white-01);
  .controller {
    padding: 0 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    svg {
      fill: var(--blue);
    }
  }
  .filter {
    padding-bottom: 12px;
  }
  .list {
    padding: 0px 12px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    height: calc(100% - 80px);
    overflow: auto;
  }
}
