/* NOTE: Font size class name */
.font-us {
  font-size: var(--font-us);
}

.font-xs {
  font-size: var(--font-xs);
}

.font-sm {
  font-size: var(--font-sm);
}

.font-md {
  font-size: var(--font-md);
}

.font-lg {
  font-size: var(--font-lg);
}

.font-xl {
  font-size: var(--font-xl);
}

.font-xxl {
  font-size: var(--font-xxl);
}

.font-xxxl {
  font-size: var(--font-xxxl);
}

/* NOTE: Font color class name */
.color-white {
  color: var(--white-01);
}

.color-black {
  color: var(--black);
}

.color-blue {
  color: var(--blue);
}

.color-green {
  color: var(--green);
}

.color-orange {
  color: var(--orange);
}

.color-red {
  color: var(--red);
}

.color-grey-01 {
  color: var(--grey-01);
}

.color-grey-02 {
  color: var(--grey-02);
}

.color-grey-03 {
  color: var(--grey-03);
}

/* NOTE: Background color class name */
.background-white {
  background: var(--white-01);
}

.background-black {
  background: var(--black);
}

.background-blue {
  background: var(--blue);
}

.background-green {
  background: var(--green);
}

.background-orange {
  background: var(--orange);
}

.background-red {
  background: var(--red);
}

.background-grey-01 {
  background: var(--grey-01);
}

.background-grey-02 {
  background: var(--grey-02);
}

.background-grey-03 {
  background: var(--grey-03);
}

/* NOTE: Font weight class name */
.font-light {
  font-weight: var(--font-light);
}

.font-medium {
  font-weight: var(--font-medium);
}

.font-bold {
  font-weight: var(--font-bold);
}

/* NOTE: Common class name */
.error-message {
  font-weight: var(--font-medium);
  color: var(--red);
  font-size: var(--font-xs);
  margin-top: 6px;
}

.link {
  color: var(--blue);
  text-decoration: underline;
}

.required {
  &::after {
    content: '*';
    color: var(--red);
    position: relative;
    top: 3px;
    padding-left: 3px;
    font-size: var(--font-xl);
  }
}

.text-overflow {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
}

#notify-template {
  display: flex;
  flex-direction: column;
  gap: 6px;

  .notify-body {
    line-height: var(--font-xl);
    text-align: left;
    word-break: break-word;
    color: var(--color-black);
    font-size: var(--font-sm);
    font-weight: var(--font-light);
  }

  .notify-link {
    color: var(--blue);
    font-size: var(--font-sm);
    margin: auto;
    margin-top: 24px;

    &::before {
      content: '👉';
    }
  }
}

.notify-font-medium {
  font-weight: var(--font-medium);
}

.notify-color-red {
  color: var(--red);
}

.notify-color-orange {
  color: var(--orange);
}
