.container {
  .header {
    height: 56px;
    display: flex;
    align-items: center;
    padding: 0 24px;
    position: fixed;
    top: 0;
    width: calc(100vw - 48px);
    z-index: 100;
    p {
      margin: 0 24px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      padding: 12px 0px;
    }
  }
  .content {
    height: calc(100vh - 56px);
    background: var(--white);
    margin-top: 56px;
  }
}
