.toastWrapper {
  pointer-events: none !important;
  :global(.adm-toast-main) {
    padding: 24px;
    min-width: 280px;
    text-align: center;
  }
  :global(.adm-toast-icon) {
    text-align: center;
    display: flex;
    justify-content: center;
    margin-bottom: 12px !important;
  }
}
