.form {
  &.error {
    :global(.adm-list-body) {
      border: 2px solid var(--red);
    }
  }

  :global(.adm-list-body) {
    border-radius: 8px;
    border: 1px solid var(--grey-03);
  }

  :global(.adm-list-item-content-main) {
    padding: 6px 0;
  }

  .title {
    :global(.adm-form-item-label) {
      font-size: var(--font-sm);
      color: var(--grey-01);
      margin-bottom: 0;
    }
    :global(.adm-input-element) {
      font-size: var(--font-md);
      color: var(--black);
    }
  }
}
