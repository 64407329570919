.wrapper {
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;

  .info {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .fieldInfo {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 12px;
    }

    .noteStatus {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 6px;
    }

    .noteInfo {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 6px;
    }
  }

  .listImage {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    width: 100%;

    .imageBox {
      :global(.adm-image) {
        width: 100%;
        height: 100%;
        border-radius: 8px;
        overflow: hidden;

        img {
          object-fit: cover !important;
        }
      }
    }
  }

  .history {
    .historyList {
      margin-top: 12px;
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
  }
}
