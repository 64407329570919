.container {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  background-color: var(--blue);
  animation: show 2s linear 0.5s 1 normal forwards;
  z-index: 1000;
  background-image: url('../../../../public/images/backgrounds/svg/pattern-01.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom;
  .logo {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 24px;
    top: -10%;
    justify-content: center;
    align-items: center;
    p.animation {
      -webkit-animation: blurFadeInOut 3s ease-in backwards;
      -moz-animation: blurFadeInOut 3s ease-in backwards;
      -ms-animation: blurFadeInOut 3s ease-in backwards;
      animation: blurFadeInOut 3s ease-in backwards;
      -webkit-animation-delay: 0s;
      -moz-animation-delay: 0s;
      -ms-animation-delay: 0s;
      animation-delay: 0s;
    }
  }

  p.subTitle {
    position: absolute;
    bottom: 32px;
  }
}

@keyframes show {
  0% {
    opacity: 1;
    display: flex;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    display: none;
  }
}

@keyframes blurFadeInOut {
  0% {
    opacity: 0;
    text-shadow: 0px 0px 20px #fff;
    -webkit-transform: scale(1.3);
  }
  20%,
  75% {
    opacity: 1;
    text-shadow: 0px 0px 1px #fff;
    -webkit-transform: scale(1);
  }
  100% {
    opacity: 0;
    text-shadow: 0px 0px 30px #fff;
    -webkit-transform: scale(0);
  }
}
