.transactionCard {
  border-radius: 8px;
  padding: 12px;
  display: flex;
  align-items: center;
  gap: 12px;
  position: relative;
  background: var(--grey-04);
  .iconBox {
    width: 40px;
    height: 40px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--white-01);
    span {
      width: 40px;
      height: 40px;
    }
  }
  .content {
    display: flex;
    align-items: center;
    gap: 12px;
    flex: 1;
    width: calc(100% - 60px);

    justify-content: space-between;

    .left {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      gap: 6px;
      max-width: 60%;

      p {
        padding-bottom: 2px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .right {
      max-width: 40%;
      text-align: right;
    }
  }
}
