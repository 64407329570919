.wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  .logo {
    width: 80px;
    height: 80px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .content {
    margin-top: 32px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 12px;
  }
  .code {
    :global(.adm-passcode-input-seperated) {
      margin-top: 64px;
    }
    :global(.adm-passcode-input-cell) {
      width: 64px;
      height: 64px;
      background: transparent;
      border-radius: 16px;
      font-size: var(--font-xxl);
      font-weight: var(--font-bold);
      color: var(--white-01);
      --cell-gap: 12px;
      --border-color: rgba(255, 255, 252, 0.5) !important;
    }
    :global(.adm-passcode-input-cell-focused) {
      box-shadow: none !important;
      border-color: var(--white-01);
      color: var(--white-01) !important;
    }
  }
}
