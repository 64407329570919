.container {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  background-color: var(--blue);
  z-index: 1000;
  background-image: url('../../../../public/images/backgrounds/svg/pattern-01.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom;

  .logo {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 24px;
    top: -10%;
    justify-content: center;
    align-items: center;
  }

  p.subTitle {
    position: absolute;
    bottom: 32px;
  }
}

.guide {
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 100%;
  overflow: auto;
  padding: 24px 24px 200px 24px;
  background-color: var(--blue);

  :global(.adm-image) {
    border: 2px solid var(--white-01);
    border-radius: 16px;
  }
}
