.wrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;

  .content {
    display: flex;
    gap: 12px;
    flex-wrap: wrap;

    .imageBox {
      width: 80px;
      height: 80px;
      border-radius: 8px;
      object-fit: cover;
      position: relative;

      .icon {
        position: absolute;
        top: -8px;
        right: -8px;
        width: 26px;
        height: 26px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: var(--white-01);
        border-radius: 24px;
      }

      .loading {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        background-color: rgba(0, 0, 0, 0.4);
      }

      :global(.adm-image) {
        width: 100%;
        height: 100%;
        border-radius: 8px;
        overflow: hidden;

        img {
          object-fit: cover !important;
        }
      }
    }

    .addBox {
      background: var(--grey-04);
      width: 80px;
      height: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
    }
  }
}
