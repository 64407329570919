.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;

  textarea {
    width: auto;
    padding: 12px;
    background-color: var(--grey-04);
    border-radius: 8px;
    height: 300px;
    border: none;
    outline: none;
    font-size: var(--font-md);
  }
}
