.wrapper {
  border-radius: 8px;
  border: 1px solid var(--grey-04);
  padding: 12px;
  display: flex;
  align-items: center;
  gap: 12px;
  position: relative;

  .iconBox {
    width: 56px;
    height: 56px;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .content {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 6px;
    flex: 1;
    width: 100%;
    height: 56px;

    .group {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }
  }
}
