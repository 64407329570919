.wrapper {
  width: 100vw;
  height: 100vh;
  background: var(--blue);
  background-image: url('../../../public/images/backgrounds/svg/pattern-01.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position-y: 30%;

  .logo {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    padding-top: 10%;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .title {
    padding: 24px 0;
  }
}
