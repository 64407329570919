.function {
  width: 72px;
  height: 72px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 8px;
  border: 1px solid var(--grey-04);
  padding: 2px;
  span {
    width: 100%;
  }
  svg {
    fill: var(--orange);
  }
}
