.contractCard {
  border-radius: 8px;
  // border: 1px solid var(--grey-04);
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 6px;
  position: relative;
  height: 82px;
  background: var(--grey-05);
}
